import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import config from "../../../data/SiteConfig";
import offerImg from "../../../static/assets/images/offer.png";
import "./offer.scss";
import disableBrowserBackButton from 'disable-browser-back-navigation';

class Offer extends Component {
  render() {
    return (
      <div className="offer-container md-grid mobile-fix">
        <Card className="md-grid md-cell-8" >
          <div className="offer-wrapper">
            <img
              src={offerImg}
              className="offer-img"
            />
          </div>
        </Card>
      </div>
    );
  }
}

export default Offer;
